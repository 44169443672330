import Swiper from 'swiper/bundle';

export default {
    init() {

        $(document.body).on('wpcf7mailsent', function(){
            // console.log("testing form submission");
            var formId = event.detail.contactFormId; // ID of the form
            // console.log('Form ID:', formId);
            if(formId && formId == 9014){
                jQuery(".outil-form-data").fadeOut(function(){
                    jQuery(".outil-formthankyou").fadeIn();
                });                
            }
        });


        // reviews slider
        new Swiper('.review-slider', {
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            slidesPerView: 2,
            paginationClickable: true,
            spaceBetween: 20,
            breakpoints: {
                1920: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1028: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }
        });

        new Swiper('.review-slider-widget', {
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            slidesPerView: 1,
            paginationClickable: true,
            spaceBetween: 20
        });

        // // drawer menu js

        // function openNav() {
        //     document.getElementById("mySidenav").style.width = "100%";
        // }

        // 
        function openNav() {
            document.getElementById("mySidenav").style.width = "320px";
            document.getElementById("mySidenav").style.overflowX = "visible";

        }
        function closeNav() {
            document.getElementById("mySidenav").style.width = "0";
            document.getElementById("mySidenav").style.overflowX = "hidden";
        }

        jQuery(".menu-icon").on("click", function(){
            openNav();
        });

        jQuery(".closebtn").on("click", function(){
            closeNav();
        });
     

        // js for tabs show and hide on hover
 
        jQuery(document).ready(function($) {
            // Mouseenter event handler
            $('.tabs-nav li').on('mouseenter', function() {
                // Remove 'active' class from all tabs in both .tabs-nav and .tab-content
                $('.tabs-nav li').removeClass('active');
                $('.tab-content').removeClass('active');
                // Add 'active' class to the current tab in .tabs-nav
                $(this).addClass('active');
                // Show corresponding tab content based on the href attribute
                var targetTab = $(this).find('a').attr('href');
                $(targetTab).addClass('active');
            });
            // Mouseleave event handler for the header area
            $('header').on('mouseleave', function() {
                // Remove 'active' class from all tabs in both .tabs-nav and .tab-content
                $('.tabs-nav li').removeClass('active');
                $('.tab-content').removeClass('active');
            });
        });

        // js for open tabs on click 

        // $(".tab-content").not(".active").hide();
        // $(".tabs-nav li").click(function () {
        //     $(".tabs-nav li").removeClass("active");
        //     $(this).addClass("active");
        //     $(".tab-content").hide();
        //     var tabId = $(this).find("a").attr("href");
        //     $(tabId).show();
        //     event.stopPropagation();
        // });
        // $(document).click(function () {
        //     $(".tabs-nav li").removeClass("active");
        //     $(".tab-content").hide();
        // });
        // $(".tabs").click(function (event) {
        //     event.stopPropagation();
        // });


        // mobile tabs sec js

        (function ($) {
            $(function () {
                //  open and close nav 
                $('#navbar-toggle').click(function () {
                    $('nav ul').slideToggle();
                });
                // Hamburger toggle
                $('#navbar-toggle').on('click', function () {
                    this.classList.toggle('active');
                });
                // If a link has a dropdown, add sub menu toggle.
                $('nav ul li a:not(:only-child)').click(function (e) {
                    $(this).siblings('.navbar-dropdown').slideToggle("slow");
                    // Close dropdown when select another dropdown
                    $('.navbar-dropdown').not($(this).siblings()).hide("slow");
                    e.stopPropagation();
                });
                // Click outside the dropdown will remove the dropdown class
                $('html').click(function () {
                    $('.navbar-dropdown').hide();
                });
            });
        })(jQuery);

        jQuery( function( $ ) {
            // wc_single_product_params is required to continue.
            if ( typeof wc_single_product_params === 'undefined' ) {
                return false;
            }

            $( '.wc-tabs-wrapper, .woocommerce-tabs').on( 'init', function() {
                $( this ).find( '.wc-tab, .woocommerce-tabs .panel:not(.panel .panel)' ).hide();
    
                var hash  = window.location.hash;
                var url   = window.location.href;
                var $tabs = $( this ).find( '.wc-tabs, ul.tabs' ).first();
    
                if ( hash.toLowerCase().indexOf( 'comment-' ) >= 0 || hash === '#reviews' || hash === '#tab-reviews' ) {
                    $tabs.find( 'li.reviews_tab a' ).trigger( 'click' );
                } else if ( url.indexOf( 'comment-page-' ) > 0 || url.indexOf( 'cpage=' ) > 0 ) {
                    $tabs.find( 'li.reviews_tab a' ).trigger( 'click' );
                } else if ( hash === '#tab-additional_information' ) {
                    $tabs.find( 'li.additional_information_tab a' ).trigger( 'click' );
                } else {
                    $tabs.find( 'li:first a' ).trigger( 'click' );
                }
            } );
            $( '.wc-tabs li a, ul.tabs li a' ).on( 'click', function( e ) {
                e.preventDefault();
                // console.log("ul.tabs li a");
                var $tab          = $( this );
                var $tabs_wrapper = $tab.closest( '.wc-tabs-wrapper, .woocommerce-tabs' );
                var $tabs         = $tabs_wrapper.find( '.wc-tabs, ul.tabs' );
    
                $tabs.find( 'li' ).removeClass( 'active' );
                $tabs_wrapper.find( '.wc-tab, .panel:not(.panel .panel)' ).hide();
    
                $tab.closest( 'li' ).addClass( 'active' );
                $tabs_wrapper.find( '#' + $tab.attr( 'href' ).split( '#' )[1] ).show();
            } );

            // Review link
            $( 'a.woocommerce-review-link' ).on( 'click', function() {
                $( '.reviews_tab a' ).trigger( 'click' );
                return true;
            } );

            // Star ratings for comments
            $( '#rating' ).on( 'init', function() {
                $( '#rating' )
                    .hide()
                    .before(
                        '<p class="stars">\
                            <span>\
                                <a class="star-1" href="#">1</a>\
                                <a class="star-2" href="#">2</a>\
                                <a class="star-3" href="#">3</a>\
                                <a class="star-4" href="#">4</a>\
                                <a class="star-5" href="#">5</a>\
                            </span>\
                        </p>'
                    );
            } );
            $( '#respond p.stars a' ).on( 'click', function() {
                var $star   	= $( this ),
                    $rating 	= $( this ).closest( '#respond' ).find( '#rating' ),
                    $container 	= $( this ).closest( '.stars' );
    
                $rating.val( $star.text() );
                $star.siblings( 'a' ).removeClass( 'active' );
                $star.addClass( 'active' );
                $container.addClass( 'selected' );
    
                return false;
            } );
            $( '#respond #submit' ).on( 'click', function() {
                var $rating = $( this ).closest( '#respond' ).find( '#rating' ),
                    rating  = $rating.val();
    
                if ( $rating.length > 0 && ! rating && wc_single_product_params.review_rating_required === 'yes' ) {
                    window.alert( wc_single_product_params.i18n_required_rating_text );
    
                    return false;
                }
            } );
        
            // Init Tabs and Star Ratings
            $( '.wc-tabs-wrapper, .woocommerce-tabs, #rating' ).trigger( 'init' );
        } );

    },

    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    }
}

// js for remove empty p tags
document.addEventListener('DOMContentLoaded', () => {
    const paragraphs = document.querySelectorAll('p');
    paragraphs.forEach(p => {
        if (!p.textContent.trim()) {
            p.style.display = 'none';
        }
    });
});
