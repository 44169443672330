import Swiper from 'swiper/bundle';

export default {
    init() {
        const logoCarousel =
            new Swiper('.logos-slider', {
                loop: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                slidesPerView: 5,
                paginationClickable: true,
                spaceBetween: 20,
                breakpoints: {
                    1920: {
                        slidesPerView: 5,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            });
console.log(logoCarousel);
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};