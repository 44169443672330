/**
 * import external dependencies
 */
import 'jquery';
import 'swiper';
import domReady from '@roots/sage/client/dom-ready';

import Router from './util/Router';
import common from './routes/common';
import pageTemplateTemplateAbout from './routes/template-about';

/**
 * Application entrypoint
 */
domReady(async () => {
  // Custom,
  // pageTemplateTemplateAbout
});
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  pageTemplateTemplateAbout,
});
/**
/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

// jQuery(document).ready(() => routes.loadEvents());
// /**
// * Load Events
// */
$(() => routes.loadEvents());